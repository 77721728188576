import React, { FC } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Img from './Img';
import { ArticleAuthor } from '../../entities/operationResults';
import theme from '../../constants/theme';

export const authorImageFragment = graphql`
    fragment ArticleAuthor on Craft_VolumeInterface {
        title
        url(crop: { width: 60, height: 60, position: topCenter, quality: 100 })
        width
        height
    }
`;

interface Props {
    authorImage: ArticleAuthor;
    type: string | null;
}

const AuthorImage: FC<Props> = ({ authorImage, type }) => {
    return (
        <Background type={type}>
            <Photo
                src={authorImage.url ?? undefined}
                alt={authorImage.title || undefined}
                ratio={parseInt(authorImage.height || '0', 10) / parseInt(authorImage.width || '0', 10)}
            />
        </Background>
    );
};

const Photo = styled(Img)`
    height: 6rem;
    width: 6rem;
    border-radius: 3rem;
    text-align: center;
    overflow: hidden;
`;

const Background = styled.div<{ type: string | null }>`
    height: 6rem;
    width: 6rem;
    background-color: ${({ type }) => {
        switch (type) {
            case 'Blog':
                return theme.colors.powderBlue;
            case 'Whitepaper':
                return theme.colors.gorse;
            case 'Medium':
                return theme.colors.lilac;
            default:
                return 'transparent';
        }
    }};
    border-radius: 3rem;
`;

export default AuthorImage;
