import React, { FC } from 'react';
import { graphql } from 'gatsby';
import { NewsArticleBlogFragment, NewsArticleMediumFragment, NewsPageQuery } from '../entities/operationResults';
import { Section } from './atoms/Section';
import Meta from './atoms/Meta';
import withDefaultTransition from '../hocs/withDefaultTransition';
import NewsBlock from './organisms/NewsBlock';
import Footer from './organisms/Footer';
import styled from 'styled-components';
import theme from '../constants/theme';

export const query = graphql`
    query NewsPageQuery($site: String, $uri: String) {
        craft {
            entry(site: $site, uri: $uri) {
                ...NewsPageFragment
            }
            entries(site: $site, type: [NewsArticleArticleBlog, NewsArticleArticleMedium]) {
                ...NewsArticleBlogFragment
                ...NewsArticleMediumFragment
            }
        }
    }
`;

export const fragment = graphql`
    fragment NewsPageFragment on Craft_News {
        id
        title
        directContact {
            ...DirectContactFragment
        }
        seo {
            ...MetaFragment
        }
    }
`;

interface Props {
    data: NewsPageQuery;
}

type Article = NewsArticleBlogFragment | NewsArticleMediumFragment;

const Nieuws: FC<Props> = ({ data }) => {
    const entry = data.craft.entry as Article;
    const title = entry.title;
    const meta = entry.seo && entry.seo[0];
    const footer = entry.directContact && entry.directContact[0];

    const newsPages = data.craft.entries ?? [];
    let newsArticles = newsPages.filter((article): article is Article => !!article);
    newsArticles = newsArticles.sort((item, prevItem) => prevItem.postDate - item.postDate);

    return (
        <>
            <Meta
                title={meta && meta.seoTitle ? meta.seoTitle : title}
                description={meta && meta.seoDescription ? meta.seoDescription : null}
                image={meta && meta.seoImage ? meta.seoImage : null}
            />
            <Section paddingLeft={2}>
                <Inner>{newsArticles && newsArticles.map((article, i) => <NewsBlock key={i} data={article} />)}</Inner>
            </Section>
            <Section variant="footer" marginLeft={2}>
                {footer && <Footer entryId={entry.id} data={footer} />}
            </Section>
        </>
    );
};

export default withDefaultTransition(Nieuws);

const Inner = styled.div`
    @media (${theme.mediaQueries.horizontal}) {
        display: flex;
        align-self: center;
    }
`;
