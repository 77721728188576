import React, { FC } from 'react';
import styled from 'styled-components';
import Title from '../atoms/Title';
import AuthorImage from '../atoms/AuthorImage';
import {
    NewsArticleBlogFragment_teamMemberEntry_Craft_TeamMember,
    NewsArticleMediumFragment_teamMemberEntry_Craft_TeamMember,
} from '../../entities/operationResults';

interface Props {
    type: string | null;
    author:
        | NewsArticleMediumFragment_teamMemberEntry_Craft_TeamMember
        | NewsArticleBlogFragment_teamMemberEntry_Craft_TeamMember
        | null;
    date: string;
    description: string;

    readingTime?: number | null;
}

const AuthorInfo: FC<Props> = ({ type, author, date, description, readingTime }) => {
    const authorName = author && `${author.firstName} ${!!author.lastName ? author.lastName : ''}`;
    const image = author && author.teamMemberImage && author.teamMemberImage[0];
    return (
        <Wrapper>
            {image && type && <AuthorImage type={type} authorImage={image} />}
            <ArticleInfo>
                <UserTitle variant="narration">{authorName}</UserTitle>
            </ArticleInfo>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
`;

const ArticleInfo = styled.div`
    margin-left: 2rem;
    display: flex;
    flex-direction: column;
    align-self: center;
    height: auto;

    & > * + * {
        margin-top: 0.2rem;
    }
`;

const UserTitle = styled(Title)`
    font-weight: 600;
    margin-bottom: 0;
`;

export default AuthorInfo;
