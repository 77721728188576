import React, { FC, useContext } from 'react';
import styled from 'styled-components';
import { graphql, Link } from 'gatsby';
import Title from '../atoms/Title';
import CmsContent from '../atoms/CmsContent';
import timestampToDate from '../../utils/timestampToDate';
import Img from '../atoms/Img';
import theme from '../../constants/theme';
import AuthorInfo from '../molecules/AuthorInfo';
import {
    NewsArticleBlogFragment,
    NewsArticleBlogFragment_teamMemberEntry_Craft_TeamMember,
    NewsArticleMediumFragment,
    NewsArticleMediumFragment_teamMemberEntry_Craft_TeamMember,
} from '../../entities/operationResults';
import { SiteContext } from '../../contexts/SiteContext';

export const newsArticleListFragment = graphql`
    fragment NewsArticleListFragment on Craft_NewsArticleArticleBlog {
        type {
            name
        }
        uri
        title
        postDate
        teamMemberEntry {
            ... on Craft_TeamMember {
                firstName
                lastName
                teamMemberImage {
                    title
                    height(transform: avatar)
                    width(transform: avatar)
                    url500: url(fit: { width: 500, quality: 100 })
                    url1000: url(fit: { width: 1000, quality: 100 })
                    url1500: url(fit: { width: 1500, quality: 100 })
                    url2000: url(fit: { width: 2000, quality: 100 })
                }
            }
        }
        coverImage {
            title
            url
            url500: url(fit: { width: 500, quality: 100 })
            url1000: url(fit: { width: 1000, quality: 100 })
            url1500: url(fit: { width: 1500, quality: 100 })
            url2000: url(fit: { width: 2000, quality: 100 })
            width
            height
        }
        intro {
            subText {
                content
            }
            text {
                content
            }
        }
    }
`;

interface Props {
    data: NewsArticleBlogFragment | NewsArticleMediumFragment;
}

const NewsBlock: FC<Props> = ({ data: entry }) => {
    const site = useContext(SiteContext);
    const type = entry.type && entry.type.name;
    const title = entry.title;
    const uri = entry.uri;
    const date = entry.postDate && timestampToDate(entry.postDate);
    const coverImage = entry.coverImage && entry.coverImage[0];
    const teamMember = entry.teamMemberEntry?.[0] as
        | NewsArticleMediumFragment_teamMemberEntry_Craft_TeamMember
        | NewsArticleBlogFragment_teamMemberEntry_Craft_TeamMember;

    const intro = entry.intro && entry.intro[0];
    const description = intro && intro.text && intro.text.content;
    let readingTime;
    if (entry.__typename !== 'Craft_NewsArticleArticleBlog') {
        readingTime = entry.readingtime;
    }
    const to = site ? `/${site}/${uri}/` : `/${uri}/`;

    return (
        <Wrapper>
            {date && description && teamMember && (
                <AuthorInfo
                    type={type}
                    author={teamMember}
                    date={date}
                    description={description}
                    readingTime={readingTime}
                />
            )}
            {coverImage && (
                <GeneralLink to={to}>
                    <CoverImg
                        ratio={parseInt(coverImage.height || '0', 10) / parseInt(coverImage.width || '0', 10)}
                        srcSet={`
                            ${coverImage.url500} 500w,
                            ${coverImage.url1000} 1000w,
                            ${coverImage.url1500} 1500w,
                            ${coverImage.url2000} 2000w
                        `}
                        src={coverImage.url2000 || undefined}
                        alt={coverImage.title || undefined}
                    />
                </GeneralLink>
            )}
            {title && (
                <GeneralLink to={to}>
                    <Title variant="small">{title}</Title>
                    {description && (<Description variant="tiny" html={description} />)}
                </GeneralLink>
            )}
        </Wrapper>
    );
};

const GeneralLink = styled(Link)`
    text-decoration: none;
    color: ${theme.colors.black};
`;

const Wrapper = styled.article`
    padding-left: 10vw;
    padding-top: 6rem;
    display: flex;
    flex-direction: column;
    justify-content: top;
    width: 32vw;
    z-index: 1;

    & > * + * {
        margin-top: 2rem;
    }

    @media screen and (${theme.mediaQueries.vertical}) {
        width: 100%;
        padding-left: 0;
        padding-top: 10vh;

        &:first-child {
            padding-top: 0;
        }
    }
`;

const Description = styled(CmsContent)`
    line-height: 3.2rem !important;
`;

const CoverImg = styled(Img)`
    margin-bottom: 1rem;
    max-height: 30vh;
`;

export default NewsBlock;
